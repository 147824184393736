<template>
  <div>
    <Datatable
      :options="options"
      :totalPages="totalPages"
      @changeCurrent="changeCurrent"
    >
      <template v-slot:thead>
        <th
          :class="{
            asc: sort == 'document_title' && order == 'asc',
            desc: sort == 'document_title' && order == 'desc',
            sortable: true,
          }"
          @click="sortEvent('document_title')"
          width="25%"
        >
          कागजातको शीर्षक
        </th>
        <th
          :class="{
            asc: sort == 'document_type' && order == 'asc',
            desc: sort == 'document_type' && order == 'desc',
            sortable: true,
          }"
          @click="sortEvent('document_type')"
          width="25%"
        >
          कागजातको प्रकार
        </th>
        <th
          :class="{
            asc: sort == 'document_source' && order == 'asc',
            desc: sort == 'document_source' && order == 'desc',
            sortable: true,
          }"
          @click="sortEvent('document_source')"
          width="20%"
        >
          कागजातकाे स्राेत
        </th>
        <th width="30%">वर्ग (उपवर्ग)</th>
      </template>

      <template v-slot:tbody>
        <tr v-if="$store.state.datatableLoading">
          <td colspan="4">Loading.....</td>
        </tr>
        <tr v-else v-for="(ele, index) in paginatedData" :key="index">
          <td>
            <router-link
              :to="{ name: 'DocumentLibraryDetail', params: { id: ele.id } }"
              >{{ ele.document_title }}</router-link
            >
          </td>
          <td>{{ ele.document_type.ne }}</td>
          <td>{{ ele.document_source.ne }}</td>
          <td>{{ listCategories(ele.categories) }}</td>
        </tr>
      </template>
    </Datatable>
  </div>
</template>

<script>
import Datatable from "./../../components/shared/Datatable.vue";
import { listCategories } from "./../../helper/helper";
export default {
  props: {
    doclist: { type: Array, required: false },
    reset: { type: Boolean, require: false },
    order: { type: String, require: false },
    sort: { type: String, require: false },
  },
  components: {
    Datatable,
  },
  data() {
    return {
      listCategories,
      options: {
        perPage: 10,
        currentPage: 1,
      },
    };
  },
  methods: {
    changeCurrent(e) {
      this.options.currentPage = e;
    },
    sortEvent(heading) {
      let event;
      if (heading == this.sort) {
        event = {
          sort: heading,
          order: this.order == "asc" ? "desc" : "asc",
        };
      } else {
        event = {
          sort: heading,
          order: "asc",
        };
      }

      this.$emit("sortEvent", event);
    },
  },
  computed: {
    paginatedData() {
      let toReturn = [];
      if (this.doclist.length) {
        let start = (this.options.currentPage - 1) * this.options.perPage;
        let end = start + this.options.perPage;
        toReturn = this.doclist.slice(start, end);
      }
      return toReturn;
    },
    totalPages() {
      if (this.doclist.length) {
        return Math.ceil(this.doclist.length / this.options.perPage);
      }
      return 0;
    },
  },
  watch: {
    reset: {
      handler() {
        this.options.currentPage = 1;
      },
    },
  },
};
</script>

<style lang="scss">
.sortable {
  cursor: pointer;
}
</style>
