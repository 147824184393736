<template>
  <div class="table-responsive">
    <table class="table table-bordered table-striped table-hover">
      <thead>
        <tr>
          <slot name="thead" />
        </tr>
      </thead>
      <tbody>
        <slot name="tbody" />
      </tbody>
    </table>
    <Pagination
      :totalPages="totalPages"
      :perPage="options.perPage"
      :currentPage="options.currentPage"
      @pagechanged="changeCurrent"
    ></Pagination>
  </div>
</template>

<script>
import Pagination from "./Pagination.vue";
export default {
  name: "Datatable",
  components: {
    Pagination,
  },
  props: {
    options: { type: Object, required: true },
    totalPages: { type: Number, required: true },
  },
  methods: {
    changeCurrent(e) {
      this.$emit("changeCurrent", e);
    },
  },
};
</script>

<style lang="scss"></style>
